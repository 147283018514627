export interface IVoucher {
    id: string;
    __typename: "Voucher";
    restriction?: string;
    text?: string;
    voucherExpires?: string;
    voucherAmount?: number;
    prototype?: any;
    description: string;
    redemptionLocation: ILocation;
}

export enum CouponType {
    Fixed = "Fixed",
    Percentage = "Percentage",
    Custom = "Custom"
}

export interface ICoupon {
    id: string;
    __typename: "Coupon";
    restriction?: string;
    prototype?: any;
    couponType: CouponType;
    couponExpires: string,
    couponAmount?: number,
    percentage: number;
    description: string;
    redemptionLocation: ILocation;
    amount: number
}

export interface IStoreCredit {
    id: string;
    __typename: "StoreCredit";
    restriction?: string;
    prototype?: any;
    storeCreditExpires: string;
    storeCreditAmount: number;
    description: string;
    redemptionLocation: ILocation;

}

export type IReward = IVoucher | ICoupon | IStoreCredit;

export interface ILocation {
    id: string,
    name: string,
    prototype?: any,
    postalCode: string,
    street1: string,
    street2: string,
    city: string,
    state: string,
    phone: string,
    locationId: string,
    website: string,
    lat: number,
    long: number,
}


export interface IShop {
    id: string,
    name: string
    logo: string
    description: string
}

export interface IRedemption {
    id: string,
    text: string
    createdAt: string
    updatedAt: string
    rejected: boolean
    approved: boolean
    canceled: boolean
    canceledAt: string
    rejectionReason: string
    approvedAt: string
    rejectedAt: string
    redemptionLocation: ILocation
    shop: IShop
    reward: IReward
}

export interface ICoordinates {
    lat: number
    long: number
}

export interface ApproveRedemptionInput {
    redemptionId: string
}

export interface RejectRedemptionInput {
    redemptionId: string,
    rejectionReason: string,
}
