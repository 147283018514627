import { gql } from "@apollo/client";

export const REDEMPTION_LOCATION_FRAGMENT = gql`
    fragment RedemptionLocationMain on RedemptionLocation {
        id,
        name,
        postalCode,
        street1,
        street2,
        city,
        state,
        phone,
        website,
        lat,
        long,
    }
`;

export const SHOP_FRAGMENT = gql`
    fragment ShopMain on BusinessLocation {
        id,
        name,
        logo,
        description
    }
`;

export const COUPON_FRAGMENT = gql`
    ${REDEMPTION_LOCATION_FRAGMENT}

    fragment CouponMain on Coupon {
        id,
        __typename,
        restriction,
        couponType,
        couponExpires: expires,
        couponAmount: amount,
        percentage,
        description,
        prototype {
            skuImage
        }
    }
`;
export const VOUCHER_FRAGMENT = gql`
    ${REDEMPTION_LOCATION_FRAGMENT}

    fragment VoucherMain on Voucher {
        id,
        __typename,
        restriction,
        prototype {
            skuImage
        }
        text,
        voucherExpires: expires,
        voucherAmount:amount,
        description
    }
`;

export const STORE_CREDIT__FRAGMENT = gql`
    ${REDEMPTION_LOCATION_FRAGMENT}

    fragment StoreCreditMain on StoreCredit {
        id,
        __typename,
        restriction,
        storeCreditExpires: expires,
        storeCreditAmount:amount,
        description
    }
`;

export const REDEMPTION_FRAGMENT = gql`
    ${SHOP_FRAGMENT}
    ${COUPON_FRAGMENT}
    ${VOUCHER_FRAGMENT}
    ${STORE_CREDIT__FRAGMENT}
    ${REDEMPTION_LOCATION_FRAGMENT}

    fragment RedemptionMain on Redemption {
        id,
        text,
        createdAt,
        updatedAt,
        rejected,
        approved,
        canceled,
        rejectionReason,
        approvedAt,
        rejectedAt,
        canceledAt,
        lat,
        long,
        redemptionLocation{
            ...RedemptionLocationMain
        },
        shop{
            ...ShopMain
        }
        reward{
            ... on StoreCredit{
                ...StoreCreditMain
            }
            ... on Voucher{
                ...VoucherMain
            }
            ... on Coupon{
                ...CouponMain
            }
        }
    }
`;

export const GET_REDEMPTION = gql`
    ${REDEMPTION_FRAGMENT}
    query GET_REDEMPTION($redemption_id:ID!){
        redemption(where: {id: $redemption_id}){
            ...RedemptionMain
        }
    }
`;


export const ACCEPT_REDEMPTION = gql`
    ${REDEMPTION_FRAGMENT}
    mutation approveRedemption($data: ApproveRedemptionInput!) {
        approveRedemption(data: $data) {
            ...RedemptionMain
        }
    }
`;

export const REJECT_REDEMPTION = gql`
    ${REDEMPTION_FRAGMENT}
    mutation rejectRedemption($data: RejectRedemtionInput!) {
        rejectRedemption(data: $data) {
            ...RedemptionMain
        }
    }
`;
