import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redemption } from "./components/redemption";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import "./App.css";
import { NotFound } from "./components/not_found";
import { setContext } from "@apollo/client/link/context";
import { buildApolloLink } from "./build_apollo_link";

const mutationLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
});
const queryLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

let client = new ApolloClient({
  link: buildApolloLink(authLink.concat(mutationLink), queryLink),
  cache: new InMemoryCache(),
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div>
          <Switch>
            <Route exact path="/redeem/:redemptionId">
              <Redemption />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    </ApolloProvider>
  );
}
