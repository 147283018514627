import {ApolloLink} from "@apollo/client";

const OPERATION_MUTATION_SET = [
    "updateCoupon",
    "acceptRedemption",
    "rejectRedemption",
    "updateStoreCredit",
    "updateVoucher"
];

/**
 * combine mutation endpoint and query enpoint into a apollo link
 * @param mutationLink
 * @param queryLink
 * @returns {ApolloLink}
 */
export function buildApolloLink(mutationLink: ApolloLink, queryLink: ApolloLink) {
    return ApolloLink.split(
        operation => {
            return OPERATION_MUTATION_SET.includes(operation.operationName);
        },
        mutationLink, // <= apollo will send to this if operation is in mutation set
        queryLink // <= otherwise will send to this
    );
}
