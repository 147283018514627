import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 64,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    marginTop: 24,
    maxWidth: 840,
  },
}));

export function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container component={"div"}>
          <Grid item container component={"div"}>
            <Typography gutterBottom variant="h2" component={"h2"}>
              Page not found :(
            </Typography>
          </Grid>
          <Grid item container component={"div"}>
            <Typography gutterBottom variant="body2" component={"p"}>
              Maybe the page you are looking for has been removed, or you typed
              in the wrong URL
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
