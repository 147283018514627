import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { IStoreCredit } from "../types";
import { getFormatter } from "../lib/format";
const formatter = getFormatter();

export interface IStoreCreditComponentProps {
  reward: IStoreCredit;
}

export function StoreCreditComponent(props: IStoreCreditComponentProps) {
  const { reward } = props;
  return (
    <>
      <Grid item container component={"div"}>
        <Typography variant="h5" gutterBottom component={"h5"}>
          <strong>Store Credit</strong>
        </Typography>
      </Grid>
      <Grid item container component={"div"}>
        <Typography variant="body2" gutterBottom component={"p"}>
          <strong>Amount:</strong> {formatter.format(reward.storeCreditAmount)}
        </Typography>
      </Grid>
      <Grid item container component={"div"}>
        <Typography variant="body2" gutterBottom component={"p"}>
          <strong>Expires: </strong>{" "}
          {moment(reward.storeCreditExpires).fromNow()}
        </Typography>
      </Grid>
      {reward.restriction ? (
        <Grid item container component={"div"}>
          <Typography variant="body2" gutterBottom component={"p"}>
            {reward.restriction}
          </Typography>
        </Grid>
      ) : null}
    </>
  );
}
