import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useMutation } from "@apollo/client";
import { ACCEPT_REDEMPTION, REJECT_REDEMPTION } from "../queries/redemption";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { VoucherComponent } from "./voucherComponent";
import { StoreCreditComponent } from "./store_credit";
import { CouponComponent } from "./couponComponent";
import {
  ApproveRedemptionInput,
  ILocation,
  IRedemption,
  IReward,
  RejectRedemptionInput,
} from "../types";

function displayRedemptionLocation(rd: ILocation) {
  return `${rd.name} (${rd.street1}, ${rd.city}, ${rd.state} ${rd.postalCode})`;
}

const useStyles = makeStyles((theme) => ({
  location: {
    flex: 1,
    marginTop: 10,
    marginLeft: 10,
  },
  root: {
    flexGrow: 1,
    marginTop: 64,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    marginTop: 24,
    maxWidth: 840,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  skuImg: {
    margin: "auto",
    marginBottom: 20,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  btnGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  shopInfo: {
    marginTop: 30,
  },
}));

function displayReward(reward: IReward) {
  let component;
  switch (reward.__typename) {
    case "Voucher":
      component = <VoucherComponent reward={reward} />;
      break;
    case "StoreCredit":
      component = <StoreCreditComponent reward={reward} />;
      break;
    case "Coupon":
      component = <CouponComponent reward={reward} />;
      break;
    default:
      return null;
  }
  return component;
}

interface RedemptionFormProps {
  redemption: IRedemption;
}

export function RedemptionForm(props: RedemptionFormProps) {
  const { redemption } = props;
  const { approved, rejected, canceled } = redemption;
  const { shop } = redemption;

  const classes = useStyles();
  let sku;
  let reward = redemption.reward;
  const [acceptRedemption, { loading: acceptLoading, error: acceptError }] =
    useMutation<{ redemption: IRedemption }, { data: ApproveRedemptionInput }>(
      ACCEPT_REDEMPTION
    );
  const [rejectRedemption, { loading: rejectLoading, error: rejectError }] =
    useMutation<{ redemption: IRedemption }, { data: RejectRedemptionInput }>(
      REJECT_REDEMPTION
    );

  if (acceptError) {
    console.error(acceptError, "acceptError");
  }

  if (rejectError) {
    console.error(rejectError);
  }

  let [willReject, setWillReject] = useState(false);
  let [rejectReason, setRejectReason] = useState("");

  let pending = !approved && !rejected && !canceled;
  let location;
  let footer;

  if (pending) {
    if (!willReject) {
      footer = (
        <>
          <Grid item container className={classes.btnGroup} component={"div"}>
            <Button
              variant="contained"
              href={undefined}
              color="primary"
              type="submit"
              disabled={acceptLoading}
              onClick={() => {
                acceptRedemption({
                  variables: {
                    data: {
                      redemptionId: redemption.id,
                    },
                  },
                }).catch((e) => console.error(e));
              }}
            >
              Confirm Redemption
            </Button>
            {acceptLoading ? (
              <CircularProgress />
            ) : (
              <Button
                href={undefined}
                variant="contained"
                color="secondary"
                disabled={acceptLoading}
                onClick={() => setWillReject(!willReject)}
              >
                Reject
              </Button>
            )}
          </Grid>
          {acceptError ? (
            <Grid item container component={"div"}>
              <Alert severity="error">
                Internal server error in the confirmation process, please
                contact the administrator{" "}
              </Alert>
            </Grid>
          ) : null}
        </>
      );
    } else {
      footer = (
        <>
          <Grid item container component={"div"}>
            <TextField
              label="Rejection reason"
              fullWidth
              helperText="Please enter a reason for rejection"
              onChange={(event) => setRejectReason(event.target.value)}
            />
          </Grid>
          <Grid item container className={classes.btnGroup} component={"div"}>
            <Button
              variant="contained"
              color="primary"
              href={undefined}
              disabled={rejectLoading || rejectReason.trim() === ""}
              onClick={() =>
                rejectRedemption({
                  variables: {
                    data: {
                      redemptionId: redemption.id,
                      rejectionReason: rejectReason,
                    },
                  },
                }).catch((e) => console.error(e))
              }
            >
              Confirm
            </Button>
            {rejectLoading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="secondary"
                href={undefined}
                onClick={() => {
                  setRejectReason("");
                  setWillReject(false);
                }}
              >
                Cancel
              </Button>
            )}
          </Grid>
          {rejectError ? (
            <Grid item container component={"div"}>
              <Alert severity="error">
                Internal server error in the rejection process, please contact
                the administrator{" "}
              </Alert>
            </Grid>
          ) : null}
        </>
      );
    }
  } else {
    if (approved) {
      if (reward.prototype.skuImage) {
        sku = (
          <img
            className={classes.skuImg}
            alt="complex"
            src={reward.prototype.skuImage}
          />
        );
      }

      footer = (
        <Grid item container component={"div"}>
          <Alert severity="success">
            <AlertTitle>Redeemed</AlertTitle>
            <strong>Transaction Id: </strong>
            <Typography gutterBottom variant="h5" component={"h5"}>
              {redemption.id}
            </Typography>
            <strong>{moment(redemption.approvedAt).fromNow()}</strong>
          </Alert>
        </Grid>
      );
    } else if (canceled) {
      footer = (
        <Grid item container component={"div"}>
          <Alert severity="error">
            <AlertTitle>Canceled</AlertTitle>
            <strong>{moment(redemption.canceledAt).fromNow()}</strong>
          </Alert>
        </Grid>
      );
    } else if (rejected) {
      footer = (
        <Grid item container component={"div"}>
          <Alert severity="error">
            <AlertTitle>Rejected</AlertTitle>
            <strong>{moment(redemption.rejectedAt).fromNow()}</strong>
            <br />
            {redemption.rejectionReason}
          </Alert>
        </Grid>
      );
    }
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} component={"div"}>
          <Grid item component={"div"}>
            <ButtonBase
              className={classes.image}
              href={`/redeem/${redemption.id}`}
            >
              <img className={classes.img} alt="complex" src={shop.logo} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container component={"div"}>
            <Grid
              className={classes.shopInfo}
              item
              xs
              container
              direction="column"
              spacing={2}
              component={"div"}
            >
              <Grid item xs component={"div"}>
                <Typography gutterBottom variant="h5" component={"h5"}>
                  {shop.name}
                </Typography>
                <Typography variant="body2" gutterBottom component={"p"}>
                  {shop.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.paper}>
        <Grid container spacing={2} component={"div"}>
          {displayReward(reward)}
          {location ? (
            <Grid item container component={"div"}>
              <FormControl component={"div"}>
                <InputLabel id="demo-simple-select-label">
                  <strong>Redemption Location</strong>
                </InputLabel>
                {location}
              </FormControl>
            </Grid>
          ) : null}
          <Grid item container component={"div"}>
            <Typography variant="body2" gutterBottom component={"p"}>
              <strong>Issued: </strong> {moment(redemption.createdAt).fromNow()}
            </Typography>
          </Grid>
          <Grid item container component={"div"}>
            <Typography gutterBottom variant={"body2"} component={"p"}>
              <strong>Location: </strong>
              {redemption.redemptionLocation &&
                displayRedemptionLocation(redemption.redemptionLocation)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid container spacing={2} component={"div"}>
          {sku}
        </Grid>
        <Grid container spacing={2} component={"div"}>
          {footer}
        </Grid>
      </Paper>
    </div>
  );
}
