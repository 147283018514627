import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 64,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    marginTop: 24,
    maxWidth: 840,
  },
}));

export function Errors({ messages }: { messages: string[] }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container component={"div"}>
          <Grid item container component={"div"}>
            <Typography gutterBottom variant="h2" component={"h2"}>
              Error :(
            </Typography>
          </Grid>
          <Grid item container component={"div"}>
            {messages
              ? messages.map((msg, index) => (
                  <Alert severity={"error"} key={index}>
                    {msg}
                  </Alert>
                ))
              : null}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
