import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { IVoucher } from "../types";
import { getFormatter } from "../lib/format";

const formatter = getFormatter();

export interface IVoucherComponentProps {
  reward: IVoucher;
}

export function VoucherComponent(props: IVoucherComponentProps) {
  const { reward } = props;
  return (
    <>
      <Grid item container component={"div"}>
        <Typography variant="h5" component="h5" gutterBottom>
          <strong>Voucher</strong>
        </Typography>
      </Grid>
      <Grid item container component={"div"}>
        {reward.voucherAmount ? (
          <Typography variant="h6" component="p" gutterBottom>
            {formatter.format(reward.voucherAmount || 0)}
          </Typography>
        ) : (
          <Typography variant="h6" component="p" gutterBottom>
            {reward.text}
          </Typography>
        )}
      </Grid>
      {reward.restriction ? (
        <Grid item container component={"div"}>
          <Typography variant="body2" component="p" gutterBottom>
            <strong>Restrictions:</strong> {reward.restriction}
          </Typography>
        </Grid>
      ) : null}
      {reward.voucherExpires ? (
        <Grid item container component={"div"}>
          <Typography variant="body2" component="p" gutterBottom>
            <strong>Expires: </strong> {moment(reward.voucherExpires).fromNow()}
          </Typography>
        </Grid>
      ) : null}
    </>
  );
}
