import React from "react";
import { useQuery } from "@apollo/client";
import { GET_REDEMPTION } from "../queries/redemption";
import { useParams } from "react-router-dom";
import { RedemptionForm } from "./RedeemForm";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Errors } from "./errors";
import { IRedemption } from "../types";

interface GetRedemptionVariables {
  redemption_id: string;
}

export function Redemption() {
  let { redemptionId } = useParams<{ redemptionId: string }>();
  //redeem query
  const {
    loading: is_loading_redeem,
    error: redeem_error,
    data: redeem_data,
  } = useQuery<{ redemption: IRedemption }, GetRedemptionVariables>(
    GET_REDEMPTION,
    {
      variables: {
        redemption_id: redemptionId
      }
    }
  );
  if (redeem_error) {
    console.trace(redeem_error);
  }

    if (redeem_error) {
    return <Errors messages={[redeem_error.message]} />;
  } else if (
    is_loading_redeem
  ) {
    return <LinearProgress />;
  } else if (!redeem_data || !redeem_data.redemption) {
    return <Errors messages={["Redemption not found"]} />;
  } else {
    return (
      <RedemptionForm
        redemption={redeem_data && redeem_data.redemption}
      />
    );
  }
}
